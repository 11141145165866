import { CaseServise as CaseService } from "API/CaseService";
import { ContractServise } from "API/ContractServise";
import { MarketplaceService } from "API/MarketplaceService";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import { InventoryWeaponCard } from "components/Cards";
import ColorPicker from "components/ColorPicker/ColorPicker";
import Image from "components/Image";
import Layout from "components/Layout";
import Row from "components/Row";
import Filters from "components/Templates/Filters";
import UIKIT from "components/UI";
import Button from "components/UI/Button";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import {
  ContractInventary,
  IInventoryWeaponCard,
  NewSkinContract,
} from "models/InventoryWeaponCard";
import { EModalType } from "pages/Profile/Tabs/Inventory/Header/Header";
import { Wrapper } from "pages/Profile/Tabs/Inventory/Styles";
import { useEffect, useMemo, useRef, useState } from "react";
import rarityConvector from "utils/rarityConvector";
import {
  ButClearCanvas,
  Canvas,
  ContractAnumationWrapper,
  FlexDef,
  Grid,
  HeaderItem,
  Hr,
  Info,
  ItemContr,
  LeftItem,
  Name,
  SubName,
  WeaponCardContract,
  WeaponWrap,
  WrapBlockItem,
  WrapInvent,
  WrapNewSkin,
  WrapNoSkin,
  WrapperCanvas,
  WrapperContract,
  WrapSkins,
} from "./styled";
// import { url } from "node:inspector";
import { endpoints, url } from "API";
import { BlockDiv } from "pages/Cases/CasesAnimation/styled";
import { Helmet } from "react-helmet";
import { UserService } from "API/UserService";

interface filters {
  id: number;
  single: boolean;
  name: string;
  items: filterItem[];
}
interface filterItem {
  id: number;
  item_id: number;
  class_ru: string;
}

export function Contract() {
  const [itemCont, setItemCont] = useState<
    (IInventoryWeaponCard | { inf: string })[]
  >([{ inf: "1" }, { inf: "2" }, { inf: "3" }, { inf: "4" }, { inf: "5" }]);

  useEffect(() => {
    if (itemCont.findIndex((element) => "inf" in element) == 0) {
      setRarityInv(undefined);
      setId(undefined);
    }
  }, [itemCont]);

  const isLastItemInventoryCard = () => {
    // Получаем последний элемент массива
    const lastItem = itemCont[itemCont.length - 1];

    // Проверяем, является ли последний элемент элементом типа IInventoryWeaponCard
    return "id" in lastItem;
  };

  const updateItems = (item: IInventoryWeaponCard) => {
    setIdSkin([...idSkin, Number(item.id)]);

    setItemCont((prevItems) => {
      // Если уже есть 5 элементов IInventoryWeaponCard, не добавляем новый
      if (prevItems.filter((element) => !(element as any).inf).length >= 5) {
        return prevItems;
      }

      // Создаем копию текущего массива элементов
      const newItems = [...prevItems];

      // Находим индекс первого элемента с типом { inf: string }
      const firstInfIndex = newItems.findIndex((element) => "inf" in element);

      // Если нашли индекс первого элемента с типом { inf: string },
      // заменяем его на новый объект типа IInventoryWeaponCard
      if (firstInfIndex !== -1) {
        newItems[firstInfIndex] = item;
      } else {
        // Если элементы типа { inf: string } отсутствуют,
        // добавляем новый объект типа IInventoryWeaponCard в начало массива
        newItems.unshift(item);
      }

      return newItems;
    });
  };

  const removeItem = (index: number, items: IInventoryWeaponCard) => {
    setItemCont((prevItems) => {
      // Создаем копию текущего массива элементов
      const newItems = [...prevItems];

      // Удаляем элемент по указанному индексу
      newItems.splice(index, 1);

      // Добавляем элемент inf в конец массива
      newItems.push({ inf: String(newItems.length + 1) });

      return newItems;
    });
    setIdSkin((prevItems) =>
      prevItems.filter((item) => item !== Number(items.id))
    );
  };

  const { inventory } = useAppSelector((state) => state.user);
  const { total } = useAppSelector((state) => state.user);
  const { fetchInventory } = useActions();
  const { isAuth, user } = useAppSelector((state) => state.user);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState(EModalType.fromSite);
  const [page, setPage] = useState(1);
  const [_, setFoo] = useState("Все");
  const [useClass, setUseClass] = useState<boolean>();
  const [filters, setFilters] = useState<Array<filters>>([]);
  const [_1, setFoo1] = useState("");
  const [filItem, setFilitem] = useState([""]);
  const [priceSort, setPriceSort] = useState<string | undefined>(undefined);

  const [useSortPrice, setUseSortPrice] = useState<boolean | undefined>(
    undefined
  );
  const [create, setCreate] = useState<boolean>(false);

  const [useSortRarity, setUseSortRarity] = useState<boolean | undefined>(
    undefined
  );
  const [isBrelok, setIsBrelok] = useState<boolean>(false);
  const [classId1, setClassId] = useState<number>(1);
  const [weaponId, setWeapon] = useState<number>();
  const [raritySort, setRaritySort] = useState<string | undefined>(undefined);
  const [rarutyInv, setRarityInv] = useState<number | undefined>(undefined);
  const [id, setId] = useState<number | undefined>(undefined);
  const [newInvernt, setNewInventary] = useState<ContractInventary>();
  const perPage: number = PageWidth();
  function PageWidth() {
    const screenWidth = window.screen.width;
    if (screenWidth <= 4604 && screenWidth >= 2845) {
      return 45;
    } else if (screenWidth <= 2844 && screenWidth >= 2634) {
      return 40;
    } else if (screenWidth <= 2633 && screenWidth >= 2425) {
      return 35;
    } else if (screenWidth <= 2424 && screenWidth >= 2213) {
      return 30;
    } else if (screenWidth <= 2212 && screenWidth >= 2001) {
      return 25;
    } else if (screenWidth <= 2000 && screenWidth >= 1789) {
      return 21;
    } else if (screenWidth <= 1788 && screenWidth >= 1577) {
      return 15;
    } else if (screenWidth <= 1576 && screenWidth >= 1365) {
      return 10;
    } else {
      return 21;
    }
  }

  useEffect(() => {
    const fetchFilters = async () => {
      const filters = await MarketplaceService.getFilters();
      setFilters(filters.classes);
    };
    // PageWidth();
    fetchFilters();
  }, [1]);

  useEffect(() => {
    setPage(1);
  }, [weaponId, classId1, raritySort]);

  useEffect(() => {
    setPage(1);
    fetchInventory({
      steamid64: user?.user?.steamid64 || "",
      page: 1,
      classId: 1,
      perPage: perPage,
    });
  }, [user?.user?.steamid64]);
  useEffect(() => {
    setUseClass(false);
    filters.map((item) => {
      item.items.map((i) => {
        if (i.class_ru == _1) {
          setWeapon(i.id);
        }
      });
    });
  }, [_1]);

  useMemo(() => {
    fetchInventory({
      steamid64: user?.user?.steamid64 || "",
      page: 1,
      classId: classId1,
      perPage: perPage,
      raritySort: raritySort,
      weapon: weaponId,
    });
  }, [raritySort, weaponId, classId1, create]);

  useMemo(() => {
    async function GetNewInv() {
      if (user?.user?.steamid64) {
        setNewInventary(
          await UserService.getInventory(
            user?.user?.steamid64,
            page,
            classId1,
            weaponId,
            perPage,
            raritySort,
            undefined,
            isBrelok
          )
        );
      }
    }
    if (rarutyInv == undefined) {
      fetchInventory({
        steamid64: user?.user?.steamid64 || "",
        page: page,
        classId: classId1,
        perPage: perPage,
        raritySort: raritySort,
        weapon: weaponId,
      });
    } else {
      GetNewInv();
    }
  }, [page]);

  useMemo(() => {
    setUseClass(true);
    let a;
    setFoo1("");
    filters.map((item) => {
      if (_ == item.name) {
        setClassId(item.id);
        setWeapon(undefined);
        a = item.items.map((item) => {
          return item.class_ru;
        });
      }
      return;
    });

    if (a !== null && a !== undefined) {
      setFilitem(a);
    }
  }, [_]);

  function sortRarity() {
    setPriceSort(undefined);
    setUseSortRarity(true);
    setUseSortPrice(false);
    if (raritySort == undefined) {
      setRaritySort("DESC");
    }
    if (raritySort == "ASC") {
      setRaritySort("DESC");
    }
    if (raritySort == "DESC") {
      setRaritySort("ASC");
    }
  }

  const removeItemNewInv = (idToRemove: string) => {
    // console.log(1);
    setNewInventary((prevInventary) => {
      if (!prevInventary) return undefined; // Обрабатываем случай, когда prevInventary равен undefined

      // Фильтруем массив parsed, оставляя только те элементы, у которых id не совпадает с idToRemove
      const newParsed = prevInventary.inventory.filter(
        (item) => item.id !== idToRemove
      );

      // Возвращаем новый объект ContractInventary с обновленным массивом parsed и остальными свойствами
      return {
        ...prevInventary,
        parsed: newParsed,
      };
    });
  };

  const addItemNewInv = (newItem: IInventoryWeaponCard) => {
    if (!newInvernt?.inventory.includes(newItem)) {
      setNewInventary((prevInventary) => {
        if (!prevInventary) return undefined; // Обрабатываем случай, когда prevInventary равен undefined

        // Фильтруем массив parsed, оставляя только те элементы, у которых id не совпадает с idToRemove
        const newParsed = [...prevInventary.inventory, newItem];
        // Возвращаем новый объект ContractInventary с обновленным массивом parsed и остальными свойствами
        return {
          ...prevInventary,
          parsed: newParsed,
        };
      });
    }
    setNewInventary((prev) => {
      if (!prev) return prev; // Если состояние пустое, ничего не делаем
      // Создаем новый массив, в котором удаляем дубликаты по полю id
      const uniqueParsed = prev.inventory.filter(
        (item, index, self) => self.findIndex((t) => t.id === item.id) === index
      );
      // Возвращаем новый объект состояния с уникальными элементами
      return {
        ...prev,
        parsed: uniqueParsed,
      };
    });
  };

  useMemo(() => {
    async function GetNewInv() {
      // setNewInventary(
      //   await ContractServise.getInventory(1, perPage, rarutyInv || 0, id || 0)
      // );
      if (user?.user?.steamid64) {
        setNewInventary(
          await UserService.getInventory(
            user?.user?.steamid64,
            1,
            classId1,
            weaponId,
            perPage,
            raritySort,
            [rarutyInv],
            isBrelok
          )
        );
      }
    }
    GetNewInv();

    setPage(1);
  }, [rarutyInv]);

  //-----------------------Рисовалка

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [drawing, setDrawing] = useState(false);
  const [color, setColor] = useState("blue");
  const [drawin, setDrawin] = useState<boolean>(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      setContext(ctx);
    }
  }, [create]);

  const startDrawing = (
    event:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>
  ) => {
    if (!context) return;
    setDrawing(true);
    const rect = canvasRef.current!.getBoundingClientRect();
    const clientX =
      event.nativeEvent instanceof MouseEvent
        ? event.nativeEvent.clientX
        : event.nativeEvent.touches[0].clientX;
    const clientY =
      event.nativeEvent instanceof MouseEvent
        ? event.nativeEvent.clientY
        : event.nativeEvent.touches[0].clientY;
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;
    context.beginPath();
    context.moveTo(offsetX, offsetY);
  };

  const draw = (
    event:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>
  ) => {
    if (!context || !drawing) return;
    const rect = canvasRef.current!.getBoundingClientRect();
    const clientX =
      event.nativeEvent instanceof MouseEvent
        ? event.nativeEvent.clientX
        : event.nativeEvent.touches[0].clientX;
    const clientY =
      event.nativeEvent instanceof MouseEvent
        ? event.nativeEvent.clientY
        : event.nativeEvent.touches[0].clientY;
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;
    context.lineTo(offsetX, offsetY);
    context.strokeStyle = color;
    context.stroke();
  };

  const endDrawing = () => {
    setDrawing(false);
  };

  const clearCanvas = () => {
    if (!context) return;
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
  };

  useEffect(() => {
    if (drawing) {
      setDrawin(true);
    }
  }, [drawing]);

  const [newSkin, setNewSkin] = useState<NewSkinContract>();
  function loginHandler() {
    window.location.href = url + endpoints.auth.login;
  }
  async function CreateContract() {
    const array = itemCont.map((item) => {
      if (!("inf" in item)) {
        return Number(item.id);
      }
      return -1;
    });
    const rarity: IInventoryWeaponCard = itemCont[0] as IInventoryWeaponCard;
    const info = await ContractServise.PostNewScin(
      Number(rarity.rarity),
      array,
      isBrelok
    );
    setNewSkin(info);
  }
  // useEffect(() => {
  //   console.log(newSkin);
  // }, [newSkin]);
  const { setBalance } = useActions();
  const [idSkin, setIdSkin] = useState<number[]>([]);

  const [sellItem, setSellItem] = useState<boolean>(false);

  useEffect(() => {
    if (create) {
      setSellItem(false);
    }
  }, [create]);

  const windowInnerWidth = document.documentElement.clientWidth;

  useMemo(() => {
    const canvas = canvasRef.current;

    const handleTouchMove = (event: { preventDefault: () => void }) => {
      event.preventDefault();
    };

    if (canvas) {
      canvas.addEventListener("touchmove", handleTouchMove, { passive: false });

      return () => {
        canvas.removeEventListener("touchmove", handleTouchMove);
      };
    }
  }, [canvasRef, drawin]);

  const NewInventaryMemo = useMemo(() => {
    return newInvernt?.inventory.map((items, index) => {
      if (items.skin && !idSkin.includes(Number(items.id))) {
        console.log(idSkin);
        return (
          <InventoryWeaponCard
            key={index}
            src={items.src}
            rarity={rarityConvector(+items.rarity)}
            weapon={items.weapon}
            skin={items.skin}
            cl={false}
            counter={items.counter}
            onClick={() => {
              if (!isLastItemInventoryCard()) {
                updateItems(items);

                removeItemNewInv(items.id || "");

                // console.log(1);
              }
            }}
          />
        );
      }
    });
  }, [newInvernt]);
  const InventaryMemo = useMemo(() => {
    return inventory.map((items, index) => {
      if (items.skin && (!sellItem || items.id !== newSkin?.id)) {
        console.log(items);
        return (
          <InventoryWeaponCard
            key={index}
            src={items.src}
            rarity={rarityConvector(+items.rarity)}
            weapon={items.weapon}
            skin={items.skin}
            cl={false}
            counter={items.skinInfo.amount}
            onClick={() => {
              setRarityInv(+items.rarity);
              setId(items.id);
              setIsBrelok(
                Object.keys(items.skinInfo).length == 0 ? true : false
              );
              if (rarutyInv == undefined) {
                updateItems(items);
              }
            }}
          />
        );
      }
    });
  }, [inventory]);

  return (
    <Layout
      headerElement={<div />}
      leftElement={<div />}
      activePages={true}
      total={rarutyInv == undefined ? total : newInvernt?.total}
      setPage={setPage}
      page={page}
      perPage={perPage}
    >
      <Helmet>
        <title>FREEZ-BLOOD - Контракты</title>
        <meta name="description" content={`FREEZ-BLOOD - Контракты`} />
      </Helmet>
      <WrapperContract>
        {!create ? (
          <ContractAnumationWrapper>
            <LeftItem>
              <HeaderItem>Контракты</HeaderItem>

              <WrapBlockItem>
                {/* {!item.length ? <>{for() }</> } */}
                {itemCont.map((item, index) => {
                  if ("inf" in item) {
                    return (
                      <ItemContr>
                        <Image
                          src="static/images/WeaponContract.png"
                          alt="Weapon"
                          // width="120rem"
                          height="90rem"
                          maxWidth="120rem"
                        />
                        {index + 1}
                      </ItemContr>
                    );
                  } else {
                    return (
                      <WeaponCardContract
                        rarity={rarityConvector(+item.rarity)}
                        isSelected={false}
                        onlyHoverPanel={item.skinChangerStatus?.hover || false}
                        onClick={() => {
                          addItemNewInv(item);
                          removeItem(index, item);
                        }}
                      >
                        <BlockDiv rarity={rarityConvector(+item.rarity)}>
                          &nbsp;
                        </BlockDiv>
                        <Info>
                          <Image
                            src={item.src}
                            alt={"Weapon"}
                            width={windowInnerWidth > 768 ? "180rem" : "150rem"}
                            height={
                              windowInnerWidth > 768 ? "150rem" : "120rem"
                            }
                          />
                          <Name>
                            <SubName rarity={rarityConvector(+item.rarity)}>
                              &nbsp;{item.skin || item.weapon}
                            </SubName>
                          </Name>
                        </Info>
                      </WeaponCardContract>
                    );
                  }
                })}
              </WrapBlockItem>
            </LeftItem>
            <WrapperCanvas>
              <FlexDef act={true}>
                <ColorPicker onChange={(color) => setColor(color)} />

                <Canvas
                  ref={canvasRef}
                  onMouseDown={(event) => startDrawing(event)}
                  onMouseMove={(event) => draw(event)}
                  onMouseUp={() => endDrawing()}
                  onMouseOut={() => endDrawing()}
                  onTouchStart={(event) => startDrawing(event)}
                  onTouchMove={(event) => draw(event)}
                  onTouchEnd={() => endDrawing()}
                  width={windowInnerWidth > 768 ? "400px" : "200px"}
                  height="200px"
                />
                <ButClearCanvas
                  onClick={() => {
                    clearCanvas();
                    setDrawin(false);
                  }}
                >
                  <Icons.Delete />
                </ButClearCanvas>
              </FlexDef>
              {/* <button onClick={clearCanvas}>Очистить холст</button> */}
              <Button
                color="blue"
                variant="default"
                height="5rem"
                disabled={"inf" in itemCont[4] || !drawin}
                // params={{ width: "100%" }}
                onClick={() => {
                  CreateContract();
                  setDrawing(false);
                  setCreate(true);
                  clearCanvas();
                  setIsBrelok(false);
                  fetchInventory({
                    steamid64: user?.user?.steamid64 || "",
                    page: 1,
                    classId: 1,
                    perPage: perPage,
                  });
                }}
              >
                Подписать контракт
              </Button>
            </WrapperCanvas>
          </ContractAnumationWrapper>
        ) : (
          // <InventoryWeaponCard weapon={newSkin?.weapon_name || "Ошибка"} skin={newSkin?.paint_kit || "Ошибка"} srs={ newSkin?.image || "Ошибка"} />
          <WrapNewSkin>
            {newSkin?.id ? (
              <WrapSkins rarity={rarityConvector(newSkin?.rarity || 0)}>
                <BlockDiv rarity={rarityConvector(newSkin?.rarity || 0)}>
                  &nbsp;
                </BlockDiv>
                <Image
                  src={newSkin?.image || "Ошибка"}
                  alt="Skin"
                  width="250px"
                  height="200px"
                />
                <Name>
                  <WeaponWrap>{newSkin?.weapon_name} </WeaponWrap>
                  <SubName rarity={rarityConvector(newSkin?.rarity || 0)}>
                    {newSkin?.paint_kit}
                  </SubName>
                </Name>
                <FlexDef>
                  <Button
                    variant="default"
                    height="5rem"
                    color="blue"
                    onClick={() => {
                      setCreate(false);
                      setItemCont([
                        { inf: "1" },
                        { inf: "2" },
                        { inf: "3" },
                        { inf: "4" },
                        { inf: "5" },
                      ]);
                      // canvasRef = null;
                      setDrawin(false);
                      setBalance({
                        real: user.balance.real,
                        virtual: user.balance.virtual + (newSkin?.price || 0),
                      });
                      CaseService.SoldItem([newSkin?.id || 0]);
                      removeItemNewInv(String(newSkin.id) || "");
                      // fetchInventory({
                      //   steamid64: user?.user?.steamid64 || "",
                      //   page: 1,
                      //   classId: 1,
                      //   perPage: perPage,
                      // });
                      // setRarityInv(undefined);
                      setIdSkin([]);
                      setNewSkin(undefined);
                    }}
                  >
                    Продать за {newSkin?.price} <TinyIcons.Credit />
                  </Button>
                  <Button
                    variant="default"
                    height="5rem"
                    color="blue"
                    onClick={() => {
                      setCreate(false);
                      setItemCont([
                        { inf: "1" },
                        { inf: "2" },
                        { inf: "3" },
                        { inf: "4" },
                        { inf: "5" },
                      ]);
                      // canvasRef = null;
                      setDrawin(false);
                      setNewSkin(undefined);
                      setIdSkin([]);
                    }}
                  >
                    Повторить
                  </Button>
                </FlexDef>
              </WrapSkins>
            ) : (
              <WrapperCanvas>
                <HeaderItem>
                  Ой, что-то пошло не так, повторите попытку
                </HeaderItem>
                <Button
                  variant="default"
                  height="5rem"
                  color="blue"
                  onClick={() => {
                    setCreate(false);
                    setItemCont([
                      { inf: "1" },
                      { inf: "2" },
                      { inf: "3" },
                      { inf: "4" },
                      { inf: "5" },
                    ]);
                    // canvasRef = null;
                    setDrawin(false);
                    setIdSkin([]);
                  }}
                >
                  Повторить
                </Button>
              </WrapperCanvas>
            )}
          </WrapNewSkin>
          // {
          /* </InventoryWeaponCard> */
          // }
        )}
        <WrapInvent>
          {rarutyInv !== undefined ? (
            <Hr />
          ) : (
            <Wrapper>
              <Filters params={{ width: "unset" }}>
                <UIKIT.DropDown
                  onChange={setFoo}
                  setUse={useClass}
                  // useSort={useSortPrice}
                  items={filters.map((item) => {
                    // setClassId(item.id);
                    return item.name;
                  })}
                  headParams={{
                    title: "Класс:",
                    icon: <TinyIcons.Arrow />,
                  }}
                />
                {_ !== undefined && _ !== "Все" && _ !== "Агенты" ? (
                  <UIKIT.DropDown
                    onChange={setFoo1}
                    items={filItem}
                    setUse={useClass}
                    headParams={{
                      title: "Тип:",
                      icon: <TinyIcons.Arrow />,
                    }}
                  />
                ) : null}

                <Row
                  gap="3rem"
                  fullWidth
                  justifyContent="space-between"
                  style={{
                    minWidth: windowInnerWidth > 768 ? "38.1rem" : "",
                    maxWidth: windowInnerWidth > 768 ? "43.2rem" : "",
                  }}
                >
                  <Button
                    height="0"
                    variant="Sort"
                    params={{ width: "0", padding: "0" }}
                    color="Gray"
                    onClick={() => sortRarity()}
                  >
                    <UIKIT.DropDown
                      onChange={null}
                      useSort={useSortRarity}
                      items={["rar"]}
                      headParams={{
                        title: "Редкость:",
                        icon: <TinyIcons.Arrow />,
                      }}
                    />
                  </Button>
                </Row>
              </Filters>
            </Wrapper>
          )}

          <Grid>
            {rarutyInv !== undefined ? NewInventaryMemo : InventaryMemo}
          </Grid>
        </WrapInvent>
        {!isAuth ? (
          <WrapNoSkin>
            <HeaderItem>Авторизуйтесь</HeaderItem>
            <UIKIT.Button
              color="blue"
              variant="default"
              height="50px"
              params={{ width: "100%" }}
              onClick={loginHandler}
            >
              Авторизуйтесь
            </UIKIT.Button>
          </WrapNoSkin>
        ) : null}
      </WrapperContract>
    </Layout>
  );
}
