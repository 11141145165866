import { SupportServise } from "API/SupportServise";
import { TinyIcons } from "assets/tiny-icons";
import Column from "components/Column";
import Layout from "components/Layout";
import LayoutIcon from "components/LayoutIcon";
import Tabs from "components/Templates/Tabs";
import Select from "components/UI/Select";
import { SupportContext } from "context/Support";
import { useAppSelector } from "hooks/useAppSelector";
import { MyBans } from "interfaces/Support";
import { BansTicketAdmins, ITicket, TicketAndBlockStatus } from "models/Ticket";
import { WrapBlockChange } from "pages/AdminPages/styled";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  Body,
  BodyItem,
  BodyRow,
  BodyRowBan,
  Head,
  HeadBan,
  HeadItem,
  Tickets,
  TicketStatus,
  Wrapper,
} from "./Styles";

export default function SupportAdmins() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   async function getU() {
  //     setMyBans(await SupportServise.GetBansMy());
  //     setMyTicket(await SupportServise.GetMyTicket());
  //   }
  //   getU();
  // }, []);

  function CheckStatus(stat: TicketAndBlockStatus, act?: boolean) {
    if (act && stat == TicketAndBlockStatus.CLOSED) {
      return "Не разблокирован";
    }
    if (stat == TicketAndBlockStatus.CLOSED) {
      return "Решен";
    }
    if (stat == TicketAndBlockStatus.IN_PROGRESS) {
      return "На рассмотрении";
    }
    if (stat == TicketAndBlockStatus.CREATED) {
      return "На рассмотрении";
    }
    if (stat == TicketAndBlockStatus.DELETED) {
      return "Закрыт";
    }
    if (stat == TicketAndBlockStatus.UNBANNED) {
      return "Разбанен";
    }
  }

  function formatDate(timestampString: string) {
    if (!timestampString.length) {
      return "-";
    }
    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];

    const date = new Date(timestampString);
    date.setHours(date.getHours() - 3);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  }

  const [TicketAdmin, setTicketAdmin] = useState<BansTicketAdmins>();
  const { servers } = useAppSelector((state) => state.servers);
  const [newAdminServer, setNewAdminServer] = useState<string>();
  // const [total, setTotal] = useState<number>(0);

  useMemo(() => {
    servers.forEach(async (item) => {
      if (item.server_name == newAdminServer) {
        setTicketAdmin(
          await SupportServise.GetBlockAdmin(page, 20, Number(item.id))
        );
      }
    });
  }, [newAdminServer]);

  function redirectToFullTicket(id: number | string) {
    navigate(`/tickets/${id}`);
  }

  // useEffect(() => {
  //   console.log(idModal);
  // }, [idModal]);
  const [layoutType, setLayoutType] = useState<number>(0);
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState<number>(1);
  const headTab = ["Тикеты", "Заявки на разбан"];

  useMemo(() => {
    setPage(1);
    async function getInt() {
      if (tab !== 1) {
        if (layoutType === 0) {
          setTicketAdmin(await SupportServise.GetTicketAdmin(1, 20));
          // setTotal(icketAdmin?.total);
        } else {
          setTicketAdmin(await SupportServise.GetTicketAdminClosed(1, 20));
        }
      } else {
        if (layoutType === 0) {
          setTicketAdmin(await SupportServise.GetBlockAdmin(1, 20));
        } else {
          setTicketAdmin(await SupportServise.GetBlockAdminClosed(1, 20));
        }
      }
    }
    getInt();
  }, [tab, layoutType]);
  useMemo(() => {
    async function getInt() {
      if (tab !== 1) {
        if (layoutType == 1) {
          setTicketAdmin(await SupportServise.GetTicketAdminClosed(page, 20));
        } else {
          setTicketAdmin(await SupportServise.GetTicketAdmin(page, 20));
        }
      } else {
        if (newAdminServer) {
          servers.forEach(async (item) => {
            if (item.server_name == newAdminServer) {
              if (layoutType == 1) {
                setTicketAdmin(
                  await SupportServise.GetBlockAdminClosed(
                    page,
                    20,
                    Number(item.id)
                  )
                );
              } else {
                setTicketAdmin(
                  await SupportServise.GetBlockAdmin(page, 20, Number(item.id))
                );
              }
            }
          });
        } else {
          if (layoutType == 1) {
            setTicketAdmin(await SupportServise.GetBlockAdminClosed(page, 20));
          } else {
            setTicketAdmin(await SupportServise.GetBlockAdmin(page, 20));
          }
        }
        // servers.forEach(async (item) => {
        //   if (newAdminServer) {
        //     if (item.server_name == newAdminServer) {
        //       if (layoutType == 1) {
        //         setTicketAdmin(
        //           await SupportServise.GetBlockAdminClosed(
        //             page,
        //             20,
        //             Number(item.id)
        //           )
        //         );
        //       } else {
        //         setTicketAdmin(
        //           await SupportServise.GetBlockAdmin(page, 20, Number(item.id))
        //         );
        //       }
        //     }
        //   } else {
        //     if (layoutType == 1) {
        //       setTicketAdmin(
        //         await SupportServise.GetBlockAdminClosed(page, 20)
        //       );
        //     } else {
        //       setTicketAdmin(await SupportServise.GetBlockAdmin(page, 20));
        //     }
        //   }
        // });
      }
    }
    getInt();
  }, [page]);

  return (
    <SupportContext.Provider value={{ tab, setTab }}>
      <Layout
        activePages={true}
        total={TicketAdmin?.total}
        page={page}
        perPage={20}
        setPage={setPage}
        leftElement={
          <Column gap="2rem">
            <LayoutIcon
              className={layoutType === 0 ? "active" : ""}
              onClick={() => setLayoutType(0)}
            >
              <TinyIcons.ExclamationMark />
            </LayoutIcon>
            <LayoutIcon
              className={layoutType === 1 ? "active" : ""}
              onClick={() => setLayoutType(1)}
            >
              <TinyIcons.Yuap />
            </LayoutIcon>
          </Column>
        }
        headerElement={
          <Tabs type="NewDizign" items={headTab} context={SupportContext} />
        }
      >
        <Helmet>
          <title>FREEZ-BLOOD - Поддержка</title>
          <meta name="description" content="FREEZ-BLOOD -Поддержка" />
          <meta name="robots" content="noindex, nofollow"></meta>
        </Helmet>
        {tab == 0 ? (
          <Wrapper>
            <Tickets>
              <Head>
                <HeadItem>Тема</HeadItem>
                <HeadItem>Открыл</HeadItem>
                <HeadItem>Последнее сообщение</HeadItem>
                <HeadItem>Закрыл</HeadItem>
                <HeadItem>Дата</HeadItem>
              </Head>
              {TicketAdmin &&
                TicketAdmin.result.map((item) => {
                  if (
                    layoutType == 0 &&
                    item.status !== TicketAndBlockStatus.CLOSED &&
                    item.status !== TicketAndBlockStatus.UNBANNED
                  ) {
                    return (
                      <Body onClick={() => redirectToFullTicket(item.id)}>
                        <BodyRow key={item.id}>
                          <BodyItem>{item.topic}</BodyItem>
                          <BodyItem>{item.user?.name}</BodyItem>
                          <BodyItem>{`${formatDate(
                            item.message?.created_at || ""
                          )} `}</BodyItem>
                          {/* <TicketStatus status={item.status}>
                        {item.status}
                      </TicketStatus> */}
                          <BodyItem>{item.closedBy?.name || "-"}</BodyItem>
                          <BodyItem>{formatDate(item.date)}</BodyItem>
                        </BodyRow>
                      </Body>
                    );
                  }
                })}
              {TicketAdmin &&
                TicketAdmin.result.map((item) => {
                  if (
                    layoutType == 1 &&
                    (item.status == TicketAndBlockStatus.CLOSED ||
                      item.status == TicketAndBlockStatus.UNBANNED)
                  ) {
                    return (
                      <Body onClick={() => redirectToFullTicket(item.id)}>
                        <BodyRow key={item.id}>
                          <BodyItem>{item.topic}</BodyItem>
                          <BodyItem>{item.user?.name}</BodyItem>
                          <BodyItem>
                            {formatDate(item.message?.created_at || "")}
                          </BodyItem>
                          {/* <TicketStatus status={item.status}>
                        {item.status}
                      </TicketStatus> */}
                          <BodyItem>{item.closedBy?.name}</BodyItem>
                          <BodyItem>{formatDate(item.date)}</BodyItem>
                        </BodyRow>
                      </Body>
                    );
                  }
                })}
            </Tickets>
          </Wrapper>
        ) : (
          <Wrapper>
            <WrapBlockChange>
              <Select
                label="Выберете сервер"
                items={servers.map((item) => {
                  return item.server_name;
                })}
                onChange={setNewAdminServer}
                params={{ width: "100%" }}
              />
            </WrapBlockChange>
            <Tickets>
              <HeadBan>
                <HeadItem>Открыл</HeadItem>
                <HeadItem>Сервер</HeadItem>
                <HeadItem>Статус</HeadItem>
                <HeadItem>Последнее сообщение</HeadItem>
                <HeadItem>Закрыл</HeadItem>
                <HeadItem>Дата создания</HeadItem>
              </HeadBan>

              {TicketAdmin &&
                TicketAdmin.result.map((item) => {
                  return (
                    <Body onClick={() => redirectToFullTicket(item.id)}>
                      <BodyRowBan key={item.id}>
                        <BodyItem>{item.block?.banned.name}</BodyItem>
                        <BodyItem>{item.block?.server.name}</BodyItem>
                        <TicketStatus status={item.status}>
                          {CheckStatus(item.status)}
                        </TicketStatus>
                        <BodyItem>
                          {formatDate(item.message?.created_at || "")}
                        </BodyItem>
                        <BodyItem>{item.closedBy?.name || "-"}</BodyItem>
                        {/* <TicketStatus status={item.status}>
                        {item.status}
                      </TicketStatus> */}
                        <BodyItem>{formatDate(item.date)}</BodyItem>
                      </BodyRowBan>
                    </Body>
                  );
                })}

              {/* {myTicket.map((item, index) => {
                if (item.type == TicketTypes.HELP)
                  return (
                    <Body onClick={() => redirectToFullTicket(item.id)}>
                      <BodyRow key={item.id}>
                        <BodyItem>{item.topic}</BodyItem>
                        <BodyItem>{formatDate(item.date)}</BodyItem>
                        <TicketStatus status={item.status}>
                          {item.status}
                        </TicketStatus>
                        <BodyItem>{formatDate(item.date)}</BodyItem>
                      </BodyRow>
                    </Body>
                  );
              })} */}
            </Tickets>
            {/* <Header>Заявки на разблокировку</Header> */}

            {/* <Tickets>
              <Head>
                <HeadItem>Ник на сервере</HeadItem>
                <HeadItem>Статус</HeadItem>
                <HeadItem>Дата</HeadItem>
              </Head>
              {myTicket.map((item, index) => {
                if (item.type == TicketTypes.BLOCK)
                  return (
                    <Body onClick={() => redirectToFullTicket(item.id)}>
                      <BodyRow key={item.id}>
                        <BodyItem>{item.user?.name}</BodyItem>
                        <TicketStatus status={item.status}>
                          {item.status}
                        </TicketStatus>
                        <BodyItem>{formatDate(item.date)}</BodyItem>
                      </BodyRow>
                    </Body>
                  );
              })}
            </Tickets> */}
          </Wrapper>
        )}
      </Layout>
    </SupportContext.Provider>
  );
}
